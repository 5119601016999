.button {
  border: none;
  background: 0 0;
  color: inherit;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 4px;
  color: var(--font-background-color);
  opacity: 0.7;
  cursor: pointer;
  padding: 0;
}

.button:hover {
  opacity: 1;
}

.label {
  font-size: var(--font-size-S);
  line-height: var(--line-height-S);
}
