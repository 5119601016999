.details {
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.detail {
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-size: var(--font-size-S);
  line-height: var(--line-height-S);
}

.types {
  display: flex;
  gap: 2px;
}

.value {
  color: var(--neutral-high);
}
