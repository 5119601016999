.link {
  font-weight: bold;
  font-style: italic;
  color: var(--font-background-color);
}

.link:hover {
  background-color: var(--foreground-color);
  color: var(--primary-medium);
}
