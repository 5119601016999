.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.box {
  display: flex;
  align-items: center;
  border: 1px solid var(--border-color);
  background-color: var(--input-background-color);
  border-radius: var(--border-radius-S);
  width: calc(100% - 16px);
  padding: 8px;
}

.box:focus-within {
  border: 2px solid var(--font-foreground-color);
  padding: 7px;
}

.input {
  color: var(--font-foreground-color);
  font-size: var(--font-size-M);
  background-color: var(--input-background-color);
  line-height: var(--line-height-M);
  box-shadow: none;
  outline: none;
  border: none;
  width: 100%;
}

.input::placeholder {
  color: var(--neutral-medium);
}

.ok {
  color: var(--ok-color);
}

.ko {
  color: var(--ko-color);
}

.error {
  font-size: var(--font-size-S);
  line-height: var(--line-height-S);
  align-self: flex-end;
  color: var(--ko-message-color);
}

.errorSpacer {
  height: 14px;
}
