.container {
  display: block;
  position: relative;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  cursor: pointer;

  display: flex;
  align-items: center;
  gap: var(--spacing-S);

  color: var(--font-foreground-color);
}

.checkbox {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 16px;
  height: 16px;

  background-color: var(--neutral-low);
  border-radius: var(--border-radius-S);
}

.container:hover input ~ .checkmark {
  box-sizing: border-box;
  border: 0.1em solid var(--neutral-medium);
}

.container input:checked ~ .checkmark .checked {
  height: 10px;
  width: 10px;

  background-color: var(--primary-medium);
  border-radius: var(--border-radius-XS);
}

.label {
  color: var(--font-foreground-color);
}
