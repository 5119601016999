.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 25vw;
  min-width: 300px;
  max-width: 500px;
  margin: var(--spacing-XL);

  color: var(--font-foreground-color);
}

.sprite {
  align-self: center;
}

.warning {
  text-align: right;
  font-size: var(--font-size-S);
  line-height: var(--line-height-S);
  color: var(--ko-message-color);
}

.buttons {
  display: flex;
  justify-content: flex-end;
}
