.container {
  width: 100px;
  font-size: var(--font-size-M);
  padding: 5px 10px;
  color: #fff;
  border-radius: var(--border-radius-S);
  -webkit-text-stroke: 0.1px #777848;
  text-transform: uppercase;
  text-align: center;
}
