.container {
  display: grid;
  align-items: center;
  justify-items: center;
  grid-template-rows: auto 1fr auto;
}

.content {
  display: grid;
  grid-template-rows: auto 1fr auto;

  max-width: 1250px;

  margin: var(--spacing-L);
  gap: var(--spacing-L);

  height: calc(100dvh - var(--spacing-L) * 2);
  width: calc(100vw - var(--spacing-L) * 2);
}

.main {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.main::-webkit-scrollbar {
  display: none;
}

@media (max-width: 560px) {
  .content {
    gap: var(--spacing-M);
    margin: var(--spacing-M);
    min-height: calc(100dvh - var(--spacing-M) * 2);
    width: calc(100vw - var(--spacing-M) * 2);
  }
}
