.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-S);
}

.title {
  font-size: var(--font-size-M);
  line-height: var(--line-height-M);
  font-weight: bold;
}

.title_background {
  color: var(--font-background-color);
}

.title_foreground {
  color: var(--font-foreground-color);
}
