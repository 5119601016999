.container {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-XL);

  text-align: center;
  font-size: var(--font-size-XL);
  line-height: var(--line-height-XL);
}

.content {
  max-width: 1000px;
}
