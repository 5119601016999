.loading {
  height: 100%;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.loader::-webkit-scrollbar {
  display: none;
}

.loader {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.row {
  display: flex;
  justify-content: space-between;
  gap: 16px;
}
