.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  max-width: 420px;
}

.inputs {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.forgot {
  font-size: var(--font-size-S);
  color: var(--font-background-color);
  text-align: right;
  text-decoration: none;
  cursor: pointer;
}

.action {
  color: var(--primary-medium-background);
  text-decoration: none;
  font-weight: bold;
}

.action:hover {
  text-decoration: underline;
  cursor: pointer;
}

.header {
  text-align: center;
}

.footer {
  text-align: center;
}
