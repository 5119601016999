.buttons {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}

.tableContainer {
  width: 100%;
  overflow-x: auto;
}

.table {
  margin-top: 24px;
  width: 100%;
  border-collapse: collapse;
  table-layout: auto;
}

.th {
  border: solid 1px #ccc;
  height: 40px;
}

.td {
  border: solid 1px #ccc;
}

.rowSprite {
  width: 100%;
  display: flex;
  justify-content: center;
}

.rowButtons {
  display: flex;
  justify-content: center;
  gap: 16px;
  padding: 0 16px;
}

.rowText {
  padding: 0 16px;
}

@media (max-width: 768px) {
  .td:nth-child(2),
  .th:nth-child(2) {
    display: none;
  }

  .rowButtons {
    flex-direction: column;
    padding: 16px;
  }
}

@media (max-width: 500px) {
  .buttons {
    flex-direction: column;
  }
}
