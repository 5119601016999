.container {
  -webkit-user-select: none;
  user-select: none;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 38px;
  height: 38px;

  text-decoration: none;
  border: none;
  cursor: pointer;

  background-color: var(--neutral-low);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 5px 0px inset;
  border-radius: var(--border-radius-L);
  color: var(--neutral-lower);
}

.container > svg {
  color: #fff;
  width: 30px;
  height: 30px;
}

.downvote {
  background-color: var(--downvote-color);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 5px 0px;
}

.favorite {
  background-color: var(--favorite-color);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 5px 0px;
}

.upvote {
  background-color: var(--upvote-color);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 5px 0px;
}
