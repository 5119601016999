.menu {
  display: flex;
  gap: var(--spacing-M);
  flex-direction: column;
  background-color: var(--foreground-color);
  border-radius: var(--border-radius-S);
  padding: var(--spacing-M);
}

.item {
  cursor: pointer;
  padding: 5px;
  color: var(--font-foreground-color);
  border-radius: var(--border-radius-S);
  font-size: var(--font-size-S);
  line-height: var(--line-height-S);
}

.item:hover {
  background-color: var(--primary-high);
  color: var(--neutral-lower);
}

.selected {
  background-color: var(--primary-medium);
  color: var(--neutral-lower);
}

.selected:hover {
  background-color: var(--primary-high);
  color: var(--neutral-lower);
}
