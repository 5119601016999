.container {
  position: fixed;
  cursor: pointer;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100dvh;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 999;

  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  max-height: 80%;
  overflow-y: auto;
  cursor: default;
  padding: var(--spacing-2XL);
  background-color: var(--foreground-color);

  border-radius: var(--border-radius-L);
}
