.container {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr 1fr 1fr;
  align-items: center;
}

.center {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  gap: var(--spacing-M);
}

.right {
  margin-left: auto;
  margin-right: 0;
}

.logo {
  text-decoration: none;
  color: var(--font-background-color);
  display: flex;
  align-items: center;
  gap: var(--spacing-M);
}

.title {
  font-size: var(--font-size-3XL);
  line-height: 0;
}

.user {
  display: flex;
  align-items: center;
  gap: 10px;
}

.username {
  font-size: var(--font-size-M);
  line-height: var(--line-height-M);
}

.quit:hover {
  color: var(--primary-medium);
  cursor: pointer;
}

.nav {
  display: flex;
  align-items: flex-end;
  gap: 30px;
}

.hamburger {
  width: 24px;
  height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}

.ham {
  background-color: var(--font-background-color);
  height: 4px;
  width: 100%;
  border-radius: 2px;
}

@media (max-width: 992px) {
  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

@media (max-width: 560px) {
  .title {
    display: none;
  }
}
