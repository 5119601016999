.container {
  background-color: var(--foreground-color);
  border-radius: var(--border-radius-XL);
  padding: var(--spacing-L);
  display: grid;
  grid-template-columns: 1fr auto;
  gap: var(--spacing-S);
  min-height: 144px;
}

.information {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.head {
  display: flex;
  flex-direction: column;
}

.title {
  font-size: var(--font-size-M);
  line-height: var(--line-height-M);
  color: var(--font-foreground-color);
}

.name {
  font-weight: bold;
  color: var(--font-foreground-color);
}

.score {
  font-size: var(--font-size-M);
  line-height: var(--line-height-M);
  color: var(--font-foreground-color);
}

.loadingName {
  margin-top: 10px;
  height: 32px;
  background-color: var(--neutral-medium);
}

.loadingScore {
  height: 24px;
  width: 160px;
  background-color: var(--neutral-medium);
}

.loadingSprite {
  height: 144px;
  width: 144px;
  border-radius: var(--border-radius-L);
  background-color: var(--neutral-medium);
}
