.container {
  width: 100%;
  color: var(--font-foreground-color);
}

.container:hover {
  cursor: pointer;
}

@media (max-width: 740px) {
  .container {
    width: 100%;
  }
}
