.container {
  font-size: var(--font-size-M);
  line-height: var(--line-height-M);
  text-decoration: none;
  color: var(--font-color);
  cursor: pointer;
}

.container:hover {
  opacity: 1;
}

.inactive {
  opacity: 0.7;
}

.inactive:hover {
  opacity: 1;
}
