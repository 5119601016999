.container {
  position: relative;
  height: max-content;
}

.background {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  background-color: var(--foreground-color);
  border-radius: var(--border-radius-XL);
  z-index: -1;
}

.content {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: var(--spacing-L);
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-M);
}

.title {
  display: flex;
  align-items: center;
  gap: var(--spacing-S);
}

.name {
  font-size: var(--font-size-2XL);
  line-height: 0;
  font-weight: bold;
  color: var(--font-foreground-color);
}

.mediumname {
  font-size: var(--font-size-XL);
  line-height: 0;
  font-weight: bold;
  color: var(--font-foreground-color);
}

.littlename {
  font-size: var(--font-size-M);
  line-height: 0;
  font-weight: bold;
  color: var(--font-foreground-color);
}

.path {
  font-size: var(--font-size-2XL);
  color: var(--neutral-high);
}

.mediumpath {
  font-size: var(--font-size-XL);
  color: var(--neutral-high);
}

.littlepath {
  font-size: var(--font-size-M);
  color: var(--neutral-high);
}

.types {
  display: flex;
  align-items: center;
  gap: var(--spacing-M);
}

.parents {
  display: flex;
  align-items: center;
  gap: var(--spacing-M);
}

.parent {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-M);
}

.parentName {
  font-size: var(--font-size-M);
  line-height: var(--line-height-M);
  color: var(--font-foreground-color);
}

.credit {
  font-size: var(--font-size-S);
  line-height: 0;
  color: var(--neutral-high);
}

.references {
  position: absolute;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-S);
}

.reference {
  height: 24px;
  padding: 2px 6px;

  border-radius: 18px;

  border: 4px solid var(--foreground-color);
  background-color: var(--neutral-higher);
  color: var(--foreground-color);

  font-weight: 550;
  line-height: 22px;
  text-overflow: clip;

  display: flex;

  cursor: pointer;
}

.reference span {
  padding: 0;
  white-space: nowrap;
}
.reference:hover .referenceOtherLetters {
  display: block;
}

.referenceOtherLetters {
  display: none;
}

.referenceButton {
  width: 16px;
  height: 16px;
  padding: 6px;
  border-radius: 18px;
  border: 4px solid var(--foreground-color);
  background-color: var(--neutral-higher);
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
}

.referenceButtonIcon {
  width: 14px;
  height: 14px;
  color: var(--foreground-color);
}

.referenceButton:hover .referenceButtonIcon {
  width: 22px;
  height: 22px;
}
